import { defineMessages } from 'react-intl';

export default defineMessages({
    name: {
        id: 'approval.name',
        defaultMessage: 'Название',
    },
    description: {
        id: 'approval.description',
        defaultMessage: 'Описание',
    },
    createdBy: {
        id: 'approval.createdBy',
        defaultMessage: 'Создатель',
    },
    createdAt: {
        id: 'approval.createdAt',
        defaultMessage: 'Дата создания',
    },
    status: {
        id: 'approval.status',
        defaultMessage: 'Статус',
    },
    elements: {
        id: 'approval.elements',
        defaultMessage: 'Эелемент(ы) запуска',
    },
    chooseCreater: {
        id: 'approval.chooseCreater',
        defaultMessage: 'Выберите создателя',
    },
    delete: {
        id: 'approval.delete',
        defaultMessage: 'Удалить',
    },
    ALL: {
        id: 'approval.ALL',
        defaultMessage: 'Все',
    },
    IN_PROCESS: {
        id: 'approval.IN_PROCESS',
        defaultMessage: 'В процессе',
    },
    APPROVED: {
        id: 'approval.APPROVED',
        defaultMessage: 'Согласовано',
    },
    NOT_APPROVED: {
        id: 'approval.NOT_APPROVED',
        defaultMessage: 'Не согласовано',
    },
    CANCELLED: {
        id: 'approval.CANCELLED',
        defaultMessage: 'Отменено',
    },
    DRAFT: {
        id: 'approval.DRAFT',
        defaultMessage: 'Черновик',
    },
    more: {
        id: 'approval.more',
        defaultMessage: 'Еще',
    },
    period: {
        id: 'approval.period',
        defaultMessage: 'Период отображения',
    },
    deletingApproval: {
        id: 'approval.deletingApproval',
        defaultMessage: 'Удаление согласования(ий)',
    },
    deletingApprovalDescription: {
        id: 'approval.deletingApprovalDescription',
        defaultMessage: 'Согласование(ия) и все этапы будут удалены без возможности восстановления',
    },
    at: {
        id: 'approval.at',
        defaultMessage: 'в',
    },
    approvalPanel: {
        id: 'approval.approvalPanel',
        defaultMessage: 'Панель согласований',
    },
    approvalTemplates: {
        id: 'approval.approvalTemplates',
        defaultMessage: 'Шаблоны согласования',
    },
});
