import { connect } from 'react-redux';
import { ModelForm } from '../components/ModelDialog/Model/ModelForm.component';
import { TModelFormProps } from '../components/ModelDialog/Model/ModelForm.types';
import { TRootState } from '../../../reducers/root.reducer.types';
import { ServerSelectors } from '../../../selectors/entities/server.selectors';
import { ModelTypeSelectors, EMPTY_PRESET_ID } from '../../../selectors/modelType.selectors';
import { ModelType, ModelTypeGroup, NodeId, KanbanBoardType, MatrixType } from '../../../serverapi/api';
import { TTreeEntityState, TreeNode } from '../../../models/tree.types';
import { TreeItemType } from '../../Tree/models/tree';
import {
    TreeSelectors,
    getTreeItems,
    treeNestedChildrenMap,
    filterTreeIncludeTypes,
} from '../../../selectors/tree.selectors';
import { UserProfileSelectors } from '../../../selectors/userProfile.selectors';
import { LocalesService } from '../../../services/LocalesService';
import { getCurrentLocale } from '../../../selectors/locale.selectors';
import { FolderTypeSelectors } from '../../../selectors/folderType.selectors';
import { getCreatableModelTypes } from '../../../services/bll/FolderTypeBLLService';
import { KanbanModelTypeSelectors } from '../../../selectors/kanbanModelType.selectors';
import { isKanbanBoardType } from '../../AdminTools/Methodology/components/Presets/util/KanbanCardEditor.utils';
import { ModelTypeGroupSelectors } from '../../../selectors/modelTypeGroup.selectors';
import { SelectedNodesSelector } from '@/selectors/selectedNodes.selectors';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { MatrixModelTypeSelectors } from '@/selectors/matrixModelType.selectors';
import { isMatrixModelType } from '@/modules/Matrix/utils/Matrix.utils';

const getTypeTreeData = (modelTypes: ModelType[], state: TRootState, modelTypeGroups: ModelTypeGroup[]): TreeNode[] => {
    const groups = modelTypes.reduce((a: Map<string, string>, item) => {
        if (isKanbanBoardType(item) || isMatrixModelType(item)) {
            if (modelTypeGroups.length) {
                const modelTypeGroupName = modelTypeGroups.find((mtg) => mtg.id === item.groupId)?.name;

                return a.set(item.groupId, modelTypeGroupName!);
            }

            return a;
        }

        return a.set(item.modelTypeGroup.id, item.modelTypeGroup.name);
    }, new Map<string, string>());
    const currentLocale = getCurrentLocale(state);

    return Array.from(groups.keys()).map((g) => {
        return {
            nodeId: { id: g } as NodeId,
            name: groups.get(g),
            type: TreeItemType.Folder,
            hasChildren: true,
            children: modelTypes
                .filter((t) => t.groupId === g)
                .map((t) => {
                    return {
                        nodeId: { id: t.id } as NodeId,
                        name: LocalesService.internationalStringToString(t?.multilingualName, currentLocale),
                        type: TreeItemType.Model,
                    } as TreeNode;
                }),
        } as TreeNode;
    });
};

const ALLOW_NODE_TYPES = [TreeItemType.Server, TreeItemType.Repository, TreeItemType.Folder, TreeItemType.Model];

type TModelFormOwnProps = TModelFormProps & {
    serverId: string;
    parentNodeId?: NodeId;
    serverIsRoot?: boolean;
    modelType?: ModelType;
    allowAnyModelType: boolean;
    availableModelTypes?: string[];
    openedSelectNode?: boolean;
    onSelectType: (modelType: ModelType) => void;
};

const mapStateToProps = (state: TRootState, props: Partial<TModelFormOwnProps>): Partial<TModelFormProps> => {
    const { availableModelTypes, modelType, parentNodeId, onSelectType } = props;
    const serverId = props.serverId || ServerSelectors.connected(state)[0];
    let nodes: TreeNode[];
    const currentLocale = getCurrentLocale(state);

    if (!props.serverIsRoot && parentNodeId && parentNodeId.repositoryId) {
        const treeItemsById: { [id: string]: TTreeEntityState } = getTreeItems(
            serverId,
            parentNodeId.repositoryId,
        )(state);
        nodes = treeNestedChildrenMap(treeItemsById, parentNodeId.repositoryId);
    } else {
        nodes = TreeSelectors.treeStructure(state).filter((n) => n.nodeId.serverId === serverId);
    }
    nodes = filterTreeIncludeTypes(nodes, ALLOW_NODE_TYPES);

    const db: TTreeEntityState | undefined =
        parentNodeId && TreeSelectors.itemById({ ...parentNodeId, id: parentNodeId.repositoryId } as NodeId)(state);
    const presetId = (db && db.presetId) || EMPTY_PRESET_ID;
    const types: ModelType[] = ModelTypeSelectors.getAllModelTypes(serverId, presetId)(state);
    const matrixTypes: MatrixType[] = MatrixModelTypeSelectors.getAllMatrixModelTypes(presetId)(state);
    const kanbanTypes: KanbanBoardType[] = KanbanModelTypeSelectors.getAllKanbanModelTypes(presetId)(state);

    const modelTypeGroups: ModelTypeGroup[] = ModelTypeGroupSelectors.byPresetIdExcludeDeleted({ presetId, serverId })(
        state,
    );

    const formattedKanbanAndMatrixTypes = [...kanbanTypes, ...matrixTypes].map((type) => ({
        ...type,
        name: LocalesService.internationalStringToString(type?.multilingualName, currentLocale),
    })) as unknown as ModelType[];
    const allTypes: ModelType[] = [...types, ...formattedKanbanAndMatrixTypes];

    const selectedModelTypeArr = !modelType?.id
        ? []
        : allTypes
              .filter((t) => t.id === modelType.id)
              .map((t) => {
                  return {
                      nodeId: { id: t.id } as NodeId,
                      name: t.name,
                      type: isKanbanBoardType(t) ? TreeItemType.Kanban : TreeItemType.Model,
                  } as TreeNode;
              });

    let selectedNode: TreeNode | undefined =
        SelectedNodesSelector.getNode(DialogType.MODEL_DIALOG)(state) ||
        (parentNodeId && TreeSelectors.itemById(parentNodeId)(state));
    selectedNode = selectedNode?.type === TreeItemType.Server ? undefined : selectedNode;
    let availableModelTypesModelTypes: ModelType[];

    if (props.allowAnyModelType) {
        availableModelTypesModelTypes = ModelTypeSelectors.getEnabledModelTypes(serverId, presetId)(state) || [];
    } else {
        const enabledModelTypes: ModelType[] = ModelTypeSelectors.getEnabledModelTypes(serverId, presetId)(state);
        availableModelTypesModelTypes = availableModelTypes
            ? enabledModelTypes.filter((mt) => availableModelTypes?.includes(mt.id))
            : [];
    }
    // накладываем методологические фильтры
    availableModelTypesModelTypes = availableModelTypesModelTypes.filter((mt) =>
        UserProfileSelectors.isModelTypeCreatable(serverId, presetId, mt.id)(state),
    );

    // добавляем типы канбана
    availableModelTypesModelTypes = [...availableModelTypesModelTypes, ...formattedKanbanAndMatrixTypes];

    // вычисляются допустимые модели на основании разрешённых моделей родительской папки
    const parentFolderType = parentNodeId && FolderTypeSelectors.byNodeId({ presetId, nodeId: parentNodeId })(state);
    availableModelTypesModelTypes = getCreatableModelTypes(availableModelTypesModelTypes, parentFolderType);

    const modelTypesTree = getTypeTreeData(availableModelTypesModelTypes, state, modelTypeGroups);

    return {
        onSelectTypeId: (id: string) => {
            const mt: ModelType = allTypes.filter((t) => t.id === id)[0];
            if (mt && onSelectType) {
                onSelectType(mt);
            }
        },
        treeTypes: modelTypesTree,
        treeLocation: nodes,
        selectedNode,
        selectedModelType: selectedModelTypeArr?.[0] || undefined,
        openedSelectNode: props.openedSelectNode,
    };
};

export const ModelFormContainer = connect(mapStateToProps)(ModelForm);
