import * as React from 'react';
import { notification, ConfigProvider, Empty } from 'antd';
import SplitPane from 'react-split-pane';
import theme from './App.scss';
import { IGenericNotification, TNotificationEntity } from '../../../../models/notification.types';
import { IModelNotification } from '../../../../models/notification/modelNotification.types';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import {
    NotificationType,
    SHORT_NOTIFICATION_DURATION,
    MEDIUM_NOTIFICATION_DURATION,
    LONG_NOTIFICATION_DURATION,
} from '../../../../models/notificationType';
import { IStatusChangeModel } from '../../../../models/statusChangingModel.types';
import scriptStatusMessages from '../../messages/AppScriptExecutionStatuses.messages';
import notificationsMessages from '../../messages/AppNotifications.messages';
import noDataMessages from '../../../../modules/SimulationModeling/messages/SimulationSetupDialog.messages';
import { IConnectionErrorModel } from '../../../../models/connectionErrorModel.types';
import { ReactNode } from 'react';
import { IConnectionOpenModel } from '../../../../models/connectionOpenModel.types';
import { IDiagramLockError } from '../../../../models/notification/diagramLockError.types';
import electron, { remote } from '../../../../electron';
import DownloadItem = Electron.DownloadItem;
import WebContents = Electron.WebContents;
import BrowserWindow = Electron.BrowserWindow;
import { IFileDownloadedModel } from '../../../../models/fileDownloadedModel.types';
import { ErrorResponse, NodeId } from '../../../../serverapi/api';
import { IInsertNodeError } from '../../../../models/notification/insertNodeError.types';
import { IDndWrongNodeTypeError } from '../../../../models/notification/dndWrongNodeTypeError.types';
import { TreeItemType } from '../../../Tree/models/tree';
import { getAccusativeItemTypeName } from '../../../../utils/treeItemTypeName';
import { IScriptCompilationError } from '../../../../models/notification/scriptCompilationError.types';
import { APP_WORKSPACE_CONTAINER_ID } from '../../../../mxgraph/components/BPMPopupMenu.types';
import { ApprovalNoticificationType } from '@/models/approvalNoticificationData.types';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
/* tslint:disable max-line-length no-string-literal */

type TAppProps = {
    notifications: { [key: string]: TNotificationEntity };
    locale: string;
    onCloseErrorNotification?: any; // tslint:disable-line:no-any
    header?: (string | JSX.Element) | (string | JSX.Element)[];
    mainMenu?: (string | JSX.Element) | (string | JSX.Element)[];
    sidePanel?: (string | JSX.Element) | (string | JSX.Element)[];
    mainPanel?: (string | JSX.Element) | (string | JSX.Element)[];
    statusBar?: (string | JSX.Element) | (string | JSX.Element)[];
    dialogRoot?: JSX.Element;
    modelDialog?: (string | JSX.Element) | (string | JSX.Element)[];
    folderDialog?: (string | JSX.Element) | (string | JSX.Element)[];
    widgetSettings?: (string | JSX.Element) | (string | JSX.Element)[];
    instancePermissionsDialog?: (string | JSX.Element) | (string | JSX.Element)[];
    showSidePanel?: boolean;
    showWidgetPanel?: boolean;
    setDownloadDate: (serverId: string, scriptExecutionId: string) => void;
    sendDownloadedNotification: (fileName: string, filePath: string) => void;
    dowloadFile: (fileId: NodeId) => void;
} & WrappedComponentProps;

class App extends React.Component<TAppProps> {
    private openedErrorIds: string[] = [];

    private lastDownloadStartTime?: number;

    private panelSize: number = 250;

    componentDidMount() {
        if (electron) {
            const win = this.getMainWindow();

            if (!win) {
                return;
            }
            win.webContents.session.on(
                'will-download',
                (event: Event, item: DownloadItem, webContents: WebContents) => {
                    item.on('done', (updEvent: Event, state: string) => {
                        if (state === 'completed') {
                            if (!item.isPaused()) {
                                if (
                                    electron &&
                                    item.getTotalBytes() === item.getReceivedBytes() &&
                                    this.lastDownloadStartTime !== item.getStartTime()
                                ) {
                                    this.lastDownloadStartTime = item.getStartTime();
                                    this.props.sendDownloadedNotification(item.getFilename(), item.getSavePath());
                                }
                            }
                        }
                    });
                },
            );
        }
    }

    componentWillUnmount() {
        if (electron) {
            const win = this.getMainWindow();

            if (!win) {
                return;
            }
            win.webContents.session.removeAllListeners();
        }
    }

    handlerCloseErrorNotification = (closedId: string) => {
        this.openedErrorIds = this.openedErrorIds.filter((id) => id !== closedId);
        const { onCloseErrorNotification } = this.props;
        if (onCloseErrorNotification) {
            onCloseErrorNotification(closedId);
        }
    };

    getMainWindow = () => {
        const mainWindow: BrowserWindow[] = remote.BrowserWindow.getAllWindows();

        if (!mainWindow || !mainWindow[mainWindow.length - 1]) {
            return false;
        }

        return mainWindow[mainWindow.length - 1];
    };

    private getScriptNotificationDuration(model: IStatusChangeModel): number {
        if (!model || !model.scriptOperationData) {
            return 6;
        }
        if (model.scriptOperationData.status === 'SUCCESS') {
            if (model.scriptOperationData.result) {
                return 30;
            }

            return 10;
        }

        return 6;
    }

    private getScriptStatusMessageContent(model: IStatusChangeModel): string {
        const currIntl = this.props.intl;
        const { scriptOperationData, scriptName } = model;
        const statuses = {
            WAIT: currIntl.formatMessage(scriptStatusMessages.appScriptExecutionStatusWait),
            RUN: currIntl.formatMessage(scriptStatusMessages.appScriptExecutionStatusRunning),
            SUCCESS: currIntl.formatMessage(scriptStatusMessages.appScriptExecutionStatusSuccess),
            FAIL: currIntl.formatMessage(scriptStatusMessages.appScriptExecutionStatusFail),
            CANCEL: currIntl.formatMessage(scriptStatusMessages.appScriptExecutionStatusStopped),
        };
        const status: string = statuses[scriptOperationData.status!];

        if (scriptOperationData.status === 'FAIL') {
            const errors = {
                CREATION_ERROR: currIntl.formatMessage(notificationsMessages.appScriptErrorCreationMessage),
                OBJECT_LOCKED: currIntl.formatMessage(notificationsMessages.appScriptErrorLockedMessage),
                ACCESS_DENIED: currIntl.formatMessage(notificationsMessages.appScriptErrorForbiddenMessage),
                LICENSE_ERROR: currIntl.formatMessage(notificationsMessages.appScriptErrorLicenseMessage),
                CUSTOM: scriptOperationData.scriptErrorMessage,
                SECURITY_EXCEPTION: currIntl.formatMessage(notificationsMessages.appScriptSecurityExceptionMessage),
            };

            return currIntl.formatMessage(notificationsMessages.appScriptErrorStatusMessage, {
                scriptStatus: status,
                scriptName,
                error: errors[scriptOperationData.scriptErrorType || 'NONE'],
            });
        }

        return currIntl.formatMessage(notificationsMessages.appScriptChangingStatusMessage, {
            scriptStatus: status,
            scriptName,
        });
    }

    showNotification(currNotification: TNotificationEntity) {
        const currIntl = this.props.intl;

        switch (currNotification.type) {
            case NotificationType.LOW_SERVER:
                notification.info({
                    message: currIntl.formatMessage(notificationsMessages.lowServerTitle),
                    description: currIntl.formatMessage(notificationsMessages.lowServerMessage),
                    duration: SHORT_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.MODEL_RESTORE_VERSION_SUCCESS:
                notification.info({
                    message: currIntl.formatMessage(notificationsMessages.modelRestore),
                    description: currIntl.formatMessage(notificationsMessages.modelRestoreSuccess),
                    duration: SHORT_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.MODEL_RESTORE_VERSION_ERROR:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.modelRestore),
                    description: currIntl.formatMessage(notificationsMessages.modelRestoreError),
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.MODEL_REFRESH_SUCCESS:
                notification.success({
                    message: currIntl.formatMessage(notificationsMessages.modelRefresh),
                    description: currIntl.formatMessage(notificationsMessages.modelRefreshSuccess),
                    duration: SHORT_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.MODEL_REFRESH_ERROR:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.modelRefresh),
                    description: currIntl.formatMessage(notificationsMessages.modelRefreshError),
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.PAYMENT_REQUIRED:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorLicenseTitle),
                    description: currIntl.formatMessage(notificationsMessages.appErrorLicenseMessage),
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.DUPLICATE_ENTITY:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorDuplicateTitle),
                    description: currIntl.formatMessage(notificationsMessages.appErrorObjectDuplicateMessage),
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.ACCESS_DENIED_BY_PROFILE:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorDeniedByProfileTitle),
                    description: currIntl.formatMessage(notificationsMessages.appErrorDeniedByProfileMessage),
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.SYMBOL_MOVE_DISABLED:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorSymbolMoveDisabledTitle),
                    description: currIntl.formatMessage(notificationsMessages.appErrorSymbolMoveDisabledMessage),
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.USER_LOGIN_CONFLICT:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.userAlreadyExistsTitle),
                    description: currIntl.formatMessage(notificationsMessages.userAlreadyExistsMessage),
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.GROUP_ID_CONFLICT:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.groupAlreadyExistsTitle),
                    description: currIntl.formatMessage(notificationsMessages.groupAlreadyExistsMessage),
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.OBJECT_LOCK_ERROR:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorObjectLockTitle),
                    description: currIntl.formatMessage(notificationsMessages.appErrorObjectLockMessage),
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.DIAGRAM_LOCK_ERROR:
                const data: IDiagramLockError = currNotification.data as IDiagramLockError;
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorDiagramLockTitle),
                    description: currIntl.formatMessage(notificationsMessages.appErrorDiagramLockMessage, {
                        ownerName: data.lockOwner,
                    }),
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.DIAGRAM_LOCK_EMPTY_MODEL_TYPE:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorDiagramLockTitle),
                    description: currIntl.formatMessage(
                        notificationsMessages.appErrorDiagramLockModelTypeIsEmptyMessage,
                    ),
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.SERVER_ERROR:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    //@ts-ignore
                    description: currNotification.data
                        ? currNotification.data
                        : currIntl.formatMessage(notificationsMessages.appServerErrorMessage),
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.NETWORK_ERROR:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    //@ts-ignore
                    description: currNotification.data
                        ? currNotification.data
                        : currIntl.formatMessage(notificationsMessages.appNetworkErrorMessage),
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.UNEXPECTED_JSON:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    description: currIntl.formatMessage(notificationsMessages.unexpectedJSON),
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.COMMON_ERROR:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    //@ts-ignore
                    description: currNotification.data
                        ? currNotification.data
                        : currIntl.formatMessage(notificationsMessages.appErrorCommonMessage),
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.CONNECTION_LOST_ERROR:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorConnectionLostTitle),
                    description: this.generateConnectionLostErrorMessage(
                        currNotification.data as IConnectionErrorModel,
                    ),
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.INCORRECT_IMAGE_ERROR:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorUploadImageTitle),
                    description: currIntl.formatMessage(notificationsMessages.appErrorUploadImageMessage),
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.NOT_SVG_IMAGE_ERROR:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorUploadImageTitle),
                    description: currIntl.formatMessage(notificationsMessages.appErrorUploadImageMessageNotSvg),
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.CONNECTION_OPENED_NOTIFICATION:
                notification.info({
                    message: currIntl.formatMessage(notificationsMessages.appNotificationConnectionOpenTitle),
                    description: this.generateConnectionOpenNotificationMessage(
                        currNotification.data as IConnectionErrorModel,
                    ),
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.SUBSCRIBE_DB_SUCCESS:
                notification.info({
                    message: currIntl.formatMessage(notificationsMessages.appNotificationSuccessfullySubscribedTitle),
                    description: currIntl.formatMessage(
                        notificationsMessages.appNotificationSuccessfullySubscribedOnDBMessage,
                    ),
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.UNSUBSCRIBE_DB_SUCCESS:
                notification.info({
                    message: currIntl.formatMessage(notificationsMessages.appNotificationSuccessfullyUnSubscribedTitle),
                    description: currIntl.formatMessage(
                        notificationsMessages.appNotificationSuccessfullyUnsubscribedOnDBMessage,
                    ),
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.STALE_GRAPH_NOTIFICATION:
                notification.info({
                    message: currIntl.formatMessage(notificationsMessages.appNotificationStaleGraphTitle),
                    description:
                        currNotification &&
                        currNotification.data &&
                        (currNotification.data as IModelNotification).modelName
                            ? currIntl.formatMessage(notificationsMessages.appNotificationStaleGraphMessage, {
                                  diagramName: (currNotification.data as IModelNotification).modelName,
                              })
                            : currIntl.formatMessage(notificationsMessages.appNotificationStaleGraphShortMessage),
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.NEW_SCRIPT_STATUS_NOTIFICATION: {
                const statusDataModel: IStatusChangeModel = currNotification.data as IStatusChangeModel;
                const args = {
                    message: currIntl.formatMessage(notificationsMessages.appScriptChangingStatusTitle),
                    description: this.generateNewScriptStatusMessage(statusDataModel),
                    duration: this.getScriptNotificationDuration(statusDataModel),
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                };

                if (statusDataModel.scriptOperationData.status === 'FAIL') {
                    notification.error(args);
                } else {
                    notification.info(args);
                }

                break;
            }
            case NotificationType.SCRIPT_COMPILATION_ERROR: {
                const description = currIntl.formatMessage(notificationsMessages.appScriptCompilationErrorMessage, {
                    scriptName: (currNotification.data as IScriptCompilationError)?.scriptName || '',
                });
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appScriptChangingStatusTitle),
                    description,
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            }
            case NotificationType.SCRIPT_ENGINE_IS_UNAVAILABLE: {
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appScriptPrepareStatusTitle),
                    description: currIntl.formatMessage(notificationsMessages.appScripEngineUnavailableErrorMessage),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            }
            case NotificationType.FILE_DOWNLOADED:
                const fileDataModel: IFileDownloadedModel = currNotification.data as IFileDownloadedModel;
                notification.info({
                    message: currIntl.formatMessage(notificationsMessages.appNotificationFileDownloadedTitle),
                    description: this.generateFileDownloadedMessage(fileDataModel),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.IMPORT_MODEL_TYPE_SUCCESS:
                notification.info({
                    message: currIntl.formatMessage(notificationsMessages.importFileSuccessTitle),
                    description: currIntl.formatMessage(notificationsMessages.importModelTypeSuccess, {
                        type: (currNotification.data as IGenericNotification).message,
                    }),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.IMPORT_FILE_SUCCESS:
                notification.info({
                    message: currIntl.formatMessage(notificationsMessages.importFileSuccessTitle),
                    description: currIntl.formatMessage(notificationsMessages.importFileSuccess),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.IMPORT_LICENSE_SUCCESS:
                notification.success({
                    message: currIntl.formatMessage(notificationsMessages.importLicenseTitle),
                    description: currIntl.formatMessage(notificationsMessages.importLicenseSuccess),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.IMPORT_LICENSE_FAIL:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.importLicenseTitle),
                    description: currIntl.formatMessage(notificationsMessages.importLicenseimportFail),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.IMPORT_LICENSE_FILE_CORRUPTED:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.importLicenseTitle),
                    description: currIntl.formatMessage(notificationsMessages.importLicensefileCorrupted),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.IMPORT_LICENSE_LICENSE_EXIST:
                notification.warning({
                    message: currIntl.formatMessage(notificationsMessages.importLicenseTitle),
                    description: currIntl.formatMessage(notificationsMessages.importLicenselicenseExist),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.IMPORT_FILE_FAIL:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.importFileFailTitle),
                    description: currNotification.data
                        ? (currNotification.data as IGenericNotification).message
                        : currIntl.formatMessage(notificationsMessages.importFileFail),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.FORBIDDEN_ERROR:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorForbiddenTitle),
                    description: currIntl.formatMessage(notificationsMessages.appErrorForbiddenMessage),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.NOT_FOUND_ERROR:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorNotFoundTitle),
                    description: currIntl.formatMessage(notificationsMessages.appErrorNotFoundMessage),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.GOTO_ERROR:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorGotoTitle),
                    description: currIntl.formatMessage(notificationsMessages.appErrorGotoMessage),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.PROFILE_CREATE_ERROR:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.profileCreationErrorTitle),
                    description: currIntl.formatMessage(notificationsMessages.profileCreationErrorTitleMessage),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.UNAUTHORIZED_ERROR:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorUnauthorizedTitle),
                    description: currIntl.formatMessage(notificationsMessages.appErrorUnauthorizedMessage),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.GRID_OUTSIDE_DROP_ERROR:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorUnauthorizedTitle),
                    description: currIntl.formatMessage(notificationsMessages.appGridOutsideTableDropErrorMessage),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.PSD_DELETE_FIRST_ROW_ERROR:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorUnauthorizedTitle),
                    description: currIntl.formatMessage(notificationsMessages.appPsdDeleteFirstRowErrorMessage),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.PSD_DROP_NOT_SUPPORTED:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorUnauthorizedTitle),
                    description: currIntl.formatMessage(notificationsMessages.appPsdDropNotSupportedErrorMessage),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.PSD_DELETE_FIRST_COLUMN_ERROR:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorUnauthorizedTitle),
                    description: currIntl.formatMessage(notificationsMessages.appPsdDeleteFirstColumnErrorMessage),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.EPC_DELETE_EMPTY_COLUMN_ERROR:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorUnauthorizedTitle),
                    description: currIntl.formatMessage(notificationsMessages.appEPCDeleteEmptyColumnErrorMessage),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.EPC_DELETE_LAST_COLUMN_ERROR:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorUnauthorizedTitle),
                    description: currIntl.formatMessage(notificationsMessages.appEPCDeleteLastColumnErrorMessage),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.EPC_MOVE_EMPTY_COLUMN_ERROR:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorUnauthorizedTitle),
                    description: currIntl.formatMessage(notificationsMessages.appEPCMoveEmptyColumnErrorMessage),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.EPC_DELETE_LAST_ROW_ERROR:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorUnauthorizedTitle),
                    description: currIntl.formatMessage(notificationsMessages.appEPCDeleteLastRowErrorMessage),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.COPY_FAIL:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorUnauthorizedTitle),
                    description: currIntl.formatMessage(notificationsMessages.copyFail),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.COPY_GUID_SUCCESS:
                notification.info({
                    message: currIntl.formatMessage(notificationsMessages.copyIdSuccessTitle),
                    description: currIntl.formatMessage(notificationsMessages.copyIdSuccess),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.COPY_ITEM_LINK:
                notification.info({
                    message: currIntl.formatMessage(notificationsMessages.copyIdSuccessTitle),
                    description: currIntl.formatMessage(notificationsMessages.copyLink),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.COPY_ITEM_LINK_ERROR:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    description: currIntl.formatMessage(notificationsMessages.copyLinkError),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.COPY_GUID_FAIL:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorUnauthorizedTitle),
                    description: currIntl.formatMessage(notificationsMessages.copyIdFail),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.SEARCH_TEXT_LESS:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorUnauthorizedTitle),
                    description: currIntl.formatMessage(notificationsMessages.searchTextLess),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.SYSTEM_PROPERTY_FAIL:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.systemPropsErrorTitle),
                    description: (currNotification.data as IGenericNotification | undefined)?.message || '',
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.REPEAT_EDGE_DEFINITION:
                notification.info({
                    message: currIntl.formatMessage(notificationsMessages.repeatEdgeDefinition),
                    duration: SHORT_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.NONE_EXISTING_EDGE_DEFINITION:
                notification.info({
                    message: currIntl.formatMessage(notificationsMessages.noneExistingEdgeType),
                    duration: SHORT_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.SUCCESSFUL_IMPORT_VISIO:
                notification.info({
                    message: currIntl.formatMessage(notificationsMessages.successfulImportVisio),
                    duration: SHORT_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.INVALID_IMPORT_VISIO:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    description: currIntl.formatMessage(notificationsMessages.invalidFailImportVisio),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.INCORRECT_OBJECT_TYPE:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.incorrectObjectTypeTitle),
                    description: currIntl.formatMessage(notificationsMessages.incorrectObjectTypeMessage),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.INCORRECT_SYMBOL_TYPE:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.incorrectSymbolTypeTitle),
                    description: currIntl.formatMessage(notificationsMessages.incorrectSymbolTypeMessage),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.EXPORT_FAIL:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.exportFailTitle),
                    description: currNotification.data
                        ? (currNotification.data as IGenericNotification).message
                        : currIntl.formatMessage(notificationsMessages.exportError),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.DND_ERROR_MODEL_IS_LOCKED:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.insertDnDElementErrorTitle),
                    description: currNotification.data
                        ? (currNotification.data as IGenericNotification).message
                        : currIntl.formatMessage(notificationsMessages.insertDnDElementErrorDiagramIsLocked),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;

            case NotificationType.DND_ERROR_INVALID_REPOSITORY:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.insertDnDElementErrorTitle),
                    description: currNotification.data
                        ? (currNotification.data as IGenericNotification).message
                        : currIntl.formatMessage(notificationsMessages.insertDnDElementErrorAnotherRepository),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.DND_ERROR_WRONG_NODE_TYPE:
                const { type } = currNotification.data as IDndWrongNodeTypeError;
                let message;
                switch (type) {
                    case TreeItemType.Folder:
                        message = currIntl.formatMessage(notificationsMessages.insertDnDElementErrorIsFolder);
                        break;
                    case TreeItemType.Spreadsheet:
                        message = currIntl.formatMessage(notificationsMessages.insertDndElementErrorIsSpreadsheet);
                        break;
                    case TreeItemType.SimulationModeling:
                        message = currIntl.formatMessage(
                            notificationsMessages.insertDndElementErrorIsSimulationModeling,
                        );
                        break;
                    default:
                        message = currIntl.formatMessage(notificationsMessages.insertDndElementErrorDefaultMessage);
                        break;
                }

                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.insertDnDElementErrorTitle),
                    description: message,
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.DND_ERROR_CANT_CREATE_DECOMPOSITION:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.insertDnDElementErrorTitle),
                    description: currNotification.data
                        ? (currNotification.data as IGenericNotification).message
                        : currIntl.formatMessage(notificationsMessages.insertDnDElementErrorCantCreateDecomposition),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.DND_ERROR_CANT_MOVE_DELETED_NODE:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.insertDnDElementErrorTitle),
                    description: currIntl.formatMessage(notificationsMessages.insertDnDElementErrorCantMoveDeletedNode),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.ITEM_TYPE_IS_NOT_EXIST:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.itemTypeIsNotExistTitle),
                    description: currIntl.formatMessage(notificationsMessages.itemTypeIsNotExistMessage),
                    duration: SHORT_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.MODEL_TO_EDIT_NOT_FOUND:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.modelToEditNotFound),
                    description: currIntl.formatMessage(notificationsMessages.modelToEditNotFound),
                    duration: SHORT_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.OBJECT_TO_EDIT_NOT_FOUND:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appNotficationDataTitle),
                    description: currIntl.formatMessage(notificationsMessages.objectToEditNotFound),
                    duration: SHORT_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.MOVE_NODE_FAILED:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    description: currIntl.formatMessage(notificationsMessages.moveNodeFailed),
                    duration: SHORT_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.MOVE_NODES_FORBIDDEN:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    description: currIntl.formatMessage(notificationsMessages.moveNodeForbidden),
                    duration: SHORT_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.SUCCESS_SAVING_SYSTEM_PROPERTY:
                notification.success({
                    message: currIntl.formatMessage(notificationsMessages.settingsSavedTitle),
                    description: currIntl.formatMessage(notificationsMessages.successSavingSystemProperty),
                    duration: SHORT_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.EMPTY_CLIPBOARD:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.emptyClipboardTitle),
                    description: currIntl.formatMessage(notificationsMessages.emptyClipboardText),
                    duration: SHORT_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.CANNOT_PASTE_FROM_ANOTHER_REPOSITORY:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.cannotPasteFromAnotherRepository),
                    duration: SHORT_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.CANNOT_PASTE_THIS_TYPE_OF_ELEMENTS:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.cannotPasteThisTypeOfElements),
                    duration: SHORT_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.METHODOLOGY_IS_OPENED:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.methodologyIsOpened),
                    description: currIntl.formatMessage(notificationsMessages.methodologyIsOpenedDescription),
                    duration: SHORT_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.INVALID_PARENT_FOR_ELEMENT_TYPE:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.insertDnDElementErrorTitle),
                    description: currNotification.data
                        ? (currNotification.data as IGenericNotification).message
                        : currIntl.formatMessage(notificationsMessages.insertDnDElementErrorInvalidParent),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.INSERT_NODE_IN_FOLDER_TYPE: {
                const { nodeName, nodeType, folderTypeName } = currNotification.data as IInsertNodeError;

                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.moveNodeErrorTitle),
                    description: currIntl.formatMessage(notificationsMessages.moveNodeErrorDescription, {
                        folderTypeName,
                        nodeName,
                        nodeTypeName: currIntl.formatMessage(getAccusativeItemTypeName(nodeType)),
                    }),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            }
            case NotificationType.DND_ERROR_EXIST_IN_THIS_REPOSITORY:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.insertDnDElementErrorTitle),
                    description: currNotification.data
                        ? (currNotification.data as IGenericNotification).message
                        : currIntl.formatMessage(notificationsMessages.insertDnDElementErrorExistInRepository),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.INVALID_OBJECT_TYPE_FOR_THIS_FOLDER:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.insertErrorTitle),
                    description: currIntl.formatMessage(notificationsMessages.invalidObjectTypeForThisFolder),
                    duration: SHORT_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.SELECTED_ELEMENTS_CANT_BE_MOVED:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.insertDnDElementErrorTitle),
                    description: currIntl.formatMessage(notificationsMessages.selectedElementsCantBeMoved),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;

            case NotificationType.RESTART_TO_APPLY_CHANGES:
                notification.info({
                    message: currIntl.formatMessage(notificationsMessages.restartAppToApplyChangesMessage),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.ERROR_INVALID_ICON_FORMAT:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.invalidIconFormatErrorTitle),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.CHOOSE_ICON_ERROR:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.chooseIconErrorTitle),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.SAVE_ARIS_IN_EXCEL_ERROR:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.errorSaveArisInExcel),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.LOAD_ARIS_FROM_EXCEL_ERROR:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.errorSaveArisInExcel),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.LOGIN_IS_EMPTY:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    description: currIntl.formatMessage(notificationsMessages.emptyLoginError),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.ERASE_NODE:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    description: currIntl.formatMessage(notificationsMessages.eraseNodeError),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.RESTORE_NODE:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    description: currIntl.formatMessage(notificationsMessages.restoreNodeError),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.NODE_DELETED:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    description: currIntl.formatMessage(notificationsMessages.nodeDeletedError),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.UNSUPPORTED_OPERATION:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    description: currIntl.formatMessage(notificationsMessages.unsupportedOperationError),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.PASSWORD_LENGTH_IS_INCORRECT:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    description: currIntl.formatMessage(notificationsMessages.incorrectPasswordLengthError),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.PASSWORD_NOT_CONTAIN_LATIN_LETTER:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    description: currIntl.formatMessage(notificationsMessages.passwordHasNotLatinLetterError),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.PASSWORD_CONTAINS_NOT_ONLY_LATIN_LETTERS:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    description: currIntl.formatMessage(notificationsMessages.passwordOnlyLatinLettersError),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.PASSWORD_NOT_CONTAIN_BOTH_UPPERCASE_AND_LOWERCASE_LETTERS:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    description: currIntl.formatMessage(notificationsMessages.passwordCaseLettersError),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.PASSWORD_NOT_CONTAIN_DIGITS:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    description: currIntl.formatMessage(notificationsMessages.passwordHasNotDigitsError),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.PASSWORD_NOT_CONTAIN_SPECIAL_CHARACTERS:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    description: currIntl.formatMessage(notificationsMessages.passwordHasSpecialLettersError),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.NEW_PASSWORD_EQUAL_OLD_PASSWORD:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    description: currIntl.formatMessage(notificationsMessages.newPasswordError),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.PASSWORD_CHANGED_RECENTLY:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    description: currIntl.formatMessage(notificationsMessages.recentlyChangedPasswordError),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.PASSWORD_CHANGE_PROHIBITED:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    description: currIntl.formatMessage(notificationsMessages.passwordChangeProhibited),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.DEFAULT_BAD_REQUEST:
                const { code } = currNotification.data as ErrorResponse;
                let description;

                switch (code) {
                    case ApprovalNoticificationType.ApprovalTemplateNameRequired:
                        description = currIntl.formatMessage(notificationsMessages.approvalName);
                        break;
                    case ApprovalNoticificationType.ApprovalNameRequired:
                        description = currIntl.formatMessage(notificationsMessages.approvalTemplateName);
                        break;
                    case ApprovalNoticificationType.ApprovalUserNotFound:
                        description = currIntl.formatMessage(notificationsMessages.approvalUserNotFound);
                        break;
                    default:
                        description = currIntl.formatMessage(notificationsMessages.appServerErrorMessage);
                        break;
                }

                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    description,
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.NO_AUTHOR_ADD_MARKER:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.tryNoAuthorAddMarcer),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.LIMITED_FILE_SIZE_CUSTOM_MAX:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    description: currIntl.formatMessage(notificationsMessages.appErrorFileMaxSizeMessage),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.LOGIN_CONTAINS_NOT_ONLY_LATIN_LETTERS:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    description: currIntl.formatMessage(notificationsMessages.invalidChractersLogindError),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;

            case NotificationType.CHECK_LDAP_SERVER_ERROR:
            case NotificationType.CHECK_KEYCLOAK_SERVER_ERROR:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.checkServerErrorTitle),
                    description: (currNotification.data as IGenericNotification | undefined)?.message || '',
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.INVALID_REPOSITORY_ID:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    description: currIntl.formatMessage(notificationsMessages.invalidRepositoryId),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.NODE_MISSING_PARENT:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    description: currIntl.formatMessage(notificationsMessages.nodeMissingParent),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.PARENT_AND_CHILD_ID_UNEQUAL:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    description: currIntl.formatMessage(notificationsMessages.parentAndChildIdUnequal),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.REPOSITORY_PARENT_ERROR:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    description: currIntl.formatMessage(notificationsMessages.repositoryParentError),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.INCORRECT_RESOURCE_FOR_NODE:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    description: currIntl.formatMessage(notificationsMessages.incorrectResourceForNode),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.NO_USER_PROFILE_ASSIGNED:
                const userLogin = (currNotification.data as IGenericNotification | undefined)?.message || '';
                notification.warning({
                    message: currIntl.formatMessage(notificationsMessages.warning),
                    description: currIntl.formatMessage(notificationsMessages.noUserProfileAssigned, { userLogin }),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            case NotificationType.NODE_SUBSCRIBED: {
                const nodeName = (currNotification.data as IGenericNotification | undefined)?.message || '';
                notification.info({
                    message: currIntl.formatMessage(notificationsMessages.nodeSubscribedMessage),
                    description: currIntl.formatMessage(notificationsMessages.nodeSubscribedDescription, {
                        nodeName,
                    }),
                });
                break;
            }
            case NotificationType.NODE_UNSUBSCRIBED: {
                const nodeName = (currNotification.data as IGenericNotification | undefined)?.message || '';
                notification.info({
                    message: currIntl.formatMessage(notificationsMessages.nodeUnsubscribedMessage),
                    description: currIntl.formatMessage(notificationsMessages.nodeUnsubscribedDescription, {
                        nodeName,
                    }),
                });
                break;
            }
            case NotificationType.CANNOT_OPEN_SVG_ERROR:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    description: currIntl.formatMessage(notificationsMessages.cannotOpenSvgErrorDescription),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.NODE_ADDED_TO_FAVORITES: {
                notification.info({
                    message: currIntl.formatMessage(notificationsMessages.nodeAddedToFavoritesMessage),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            }
            case NotificationType.NODE_REMOVED_FROM_FAVORITES: {
                notification.info({
                    message: currIntl.formatMessage(notificationsMessages.nodeRemovedFromFavoritesMessage),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            }
            case NotificationType.APPROVAL_NOT_ALLOWED:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    description: currIntl.formatMessage(notificationsMessages.approvalNotAllowed),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;

            case NotificationType.MAX_SIZE_FOR_FILE: {
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    description: (currNotification.data as IGenericNotification | undefined)?.message || '',
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            }
            case NotificationType.BPMN_DATA_WARNING: {
                notification.warning({
                    message: currIntl.formatMessage(notificationsMessages.warning),
                    description: currIntl.formatMessage(notificationsMessages.bpmnDataDescription),
                    duration: LONG_NOTIFICATION_DURATION,
                });
                break;
            }
            case NotificationType.ATTRIBUTE_FILTER_VALIDATE_ERROR: {
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorUnauthorizedTitle),
                    description: currIntl.formatMessage(notificationsMessages.searchAttrFilterError),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            }
            case NotificationType.EDGE_IS_FORBIDDEN_TO_EDIT: {
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.blockedEdgeError),
                    description: (currNotification.data as IGenericNotification | undefined)?.message || '',
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            }
            case NotificationType.THE_CURRENT_PASSWORD_IS_INCORRECT: {
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    description: currIntl.formatMessage(notificationsMessages.currentPasswordError),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            }
            case NotificationType.CHANGE_USER_PASSWORD_SUCCESS: {
                notification.info({
                    message: currIntl.formatMessage(notificationsMessages.passwordChangeSuccess),
                    // description: currIntl.formatMessage(notificationsMessages.currentPasswordError),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            }
            case NotificationType.SYMBOLS_OR_EDGES_NOT_SUPPORTED_ON_MODEL_TYPE: {
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.incorrectObjectTypeTitle),
                    description: currIntl.formatMessage(
                        notificationsMessages.symbolsOrEdgesNotSupportedOnModelTypeMessage,
                    ),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            }

            case NotificationType.NO_VALID_SYMBOL_ON_MODEL_TYPE: {
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.incorrectObjectTypeTitle),
                    description: currIntl.formatMessage(notificationsMessages.noValidSymbolOnModelTypeMessage),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            }

            case NotificationType.EDGES_OR_EDGE_BETWEEN_SYMBOLS_NOT_SUPPORTED_ON_MODEL_TYPE: {
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.incorrectObjectTypeTitle),
                    description: currIntl.formatMessage(
                        notificationsMessages.edgesOrEdgeBetweenSymbolsNotSupportedOnModelTypeMessage,
                    ),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            }
            case NotificationType.COPY_DATABASE_SUCCESS: {
                notification.info({
                    message: currIntl.formatMessage(notificationsMessages.copyDatabaseSuccess),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            }
            case NotificationType.PUBLICATION_NODE_MARK_DELETED: {
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    description: currIntl.formatMessage(notificationsMessages.publicationNodeMarkDeleted),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            }
            case NotificationType.CONSOLIDATION_SUCCESS: {
                notification.success({
                    message: currIntl.formatMessage(notificationsMessages.consolidationSuccess),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            }
            case NotificationType.CONSOLIDATION_ERROR: {
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.consolidationFailure),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            }
            case NotificationType.PUBLICATION_NODE_READ_ACCESS_DENIED: {
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    description: currIntl.formatMessage(notificationsMessages.publicationNodeReadAccessDenied),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            }
            case NotificationType.MATRIX_IS_SAVING: {
                notification.info({
                    message: currIntl.formatMessage(notificationsMessages.matrixIsSaving),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            }
            case NotificationType.EDGE_DEFINITION_HAS_ENTRIES: {
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    description: currIntl.formatMessage(notificationsMessages.definitionHasEntries),
                });
                break;
            }
            case NotificationType.RENAME_MATRIX_HEADER_FORBIDDEN: {
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    description: currIntl.formatMessage(notificationsMessages.renameMatrixHeaderError),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            }

            case NotificationType.NEED_LICENSE_EDITOR: {
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.appErrorCommonTitle),
                    description: currIntl.formatMessage(notificationsMessages.needLicenseEditor),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            }

            case NotificationType.IMPORT_FAIL_GROUPS_NOT_UNIQUE:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.importError),
                    description: currIntl.formatMessage(notificationsMessages.importErrorDescription),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;

            case NotificationType.EXPORT_FAIL_GROUPS_NOT_UNIQUE:
                notification.error({
                    message: currIntl.formatMessage(notificationsMessages.exportError),
                    description: currIntl.formatMessage(notificationsMessages.exportErrorDescription),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                    onClose: () => this.handlerCloseErrorNotification(currNotification.id),
                });
                break;
            case NotificationType.MATRIX_HAS_REFRESHED: {
                notification.success({
                    message: currIntl.formatMessage(notificationsMessages.matrixHasRefreshed),
                    duration: SHORT_NOTIFICATION_DURATION,
                });
                break;
            }

            case NotificationType.CREATE_MODEL_TEMPLATE_SUCCESS: {
                notification.success({
                    message: currIntl.formatMessage(notificationsMessages.createModelTemplateSuccess),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
            }

            case NotificationType.MODEL_SAVING_SUCCESS: {
                notification.success({
                    message: currIntl.formatMessage(notificationsMessages.modelSaveSuccess),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
                break;
            }

            default:
                throw new Error(`No such type of notification: ${currNotification.type}`);
        }
    }

    componentDidUpdate(nextProps: TAppProps) {
        const { notifications } = this.props;
        const newIds = Object.keys(notifications).filter((id) => this.openedErrorIds.indexOf(id as never) === -1);

        if (newIds.length > 0) {
            this.openedErrorIds = [...this.openedErrorIds, ...newIds];
            newIds.forEach((id) => {
                const currNotification = notifications[id];
                if (currNotification) {
                    this.showNotification(currNotification);
                }
            });
        }
    }

    render() {
        const {
            header,
            mainMenu,
            sidePanel,
            dialogRoot,
            mainPanel,
            widgetSettings,
            statusBar,
            showSidePanel,
            showWidgetPanel,
            modelDialog,
            folderDialog,
            instancePermissionsDialog,
            intl,
            locale,
        } = this.props;

        return (
            // TODO: при локализации, код для подстановки нужного языка
            // TODO: сделать общее место хранения глобальных переводом и перенести туда noDataMessages из SimulationSetupDialog.messages

            <ConfigProvider
                locale={{ locale }}
                renderEmpty={() => (
                    <Empty
                        description={intl.formatMessage(noDataMessages.empty)}
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                )}
            >
                <section className={theme.container}>
                    {header}
                    {mainMenu}
                    <section className={theme.workspace} id={APP_WORKSPACE_CONTAINER_ID}>
                        {
                            //@ts-ignore
                            <SplitPane
                                onChange={(size: number) => {
                                    this.panelSize = size;
                                }}
                                split="vertical"
                                maxSize={showSidePanel ? 700 : 60}
                                minSize={250}
                                size={showSidePanel ? this.panelSize : 50}
                                defaultSize={this.panelSize}
                                primary="first"
                                pane2Style={{
                                    overflow: 'hidden',
                                    display: 'flex',
                                    marginBottom: '6px',
                                }}
                                pane1Style={{
                                    zIndex: 1,
                                }}
                            >
                                <div className={theme.sidePanel}>{sidePanel}</div>
                                {
                                    //@ts-ignore
                                    <SplitPane
                                        className={theme.secondSplitPane}
                                        resizerClassName={theme.resizerClassName}
                                        split="vertical"
                                        defaultSize={showWidgetPanel ? 280 : 0}
                                        primary="second"
                                        allowResize={false}
                                    >
                                        <div className={theme.mainPanel}>{mainPanel}</div>
                                        {showWidgetPanel ? widgetSettings : <div />}
                                    </SplitPane>
                                }
                            </SplitPane>
                        }
                    </section>
                    <section className={theme.statusBar}>{statusBar}</section>
                    {dialogRoot}
                    {modelDialog}
                    {folderDialog}
                    {instancePermissionsDialog}
                </section>
            </ConfigProvider>
        );
    }

    private generateFileDownloadedMessage(model: IFileDownloadedModel): ReactNode {
        const currIntl = this.props.intl;
        const { fileName, filePath } = model;

        const message = currIntl.formatMessage(notificationsMessages.appNotificationFileDownloadedMessage, {
            fileName,
        });

        return (
            <div>
                {message}
                <br />
                <Button
                    size="large"
                    onClick={() => {
                        if (electron) {
                            electron.shell.openPath(filePath);
                        }
                    }}
                >
                    {currIntl.formatMessage(notificationsMessages.appOpenFileMessage)}
                </Button>
            </div>
        );
    }

    private generateNewScriptStatusMessage(model: IStatusChangeModel): ReactNode {
        const currIntl = this.props.intl;
        const { server, scriptOperationData } = model;
        const message = this.getScriptStatusMessageContent(model);

        if (scriptOperationData.status === 'SUCCESS' && scriptOperationData.result) {
            return (
                <div>
                    {message}
                    <br />
                    <Button
                        size="large"
                        onClick={() => {
                            this.props.dowloadFile({ ...scriptOperationData.result!, serverId: server.id });
                        }}
                    >
                        {currIntl.formatMessage(notificationsMessages.appDownloadMessage)}
                    </Button>
                </div>
            );
        }

        return message;
    }

    private generateConnectionLostErrorMessage(connectionErrorModel: IConnectionErrorModel) {
        const currIntl = this.props.intl;

        return currIntl.formatMessage(notificationsMessages.appErrorConnectionLostMessage, {
            host: connectionErrorModel.hostAndPort,
        });
    }

    private generateConnectionOpenNotificationMessage(connectionOpenModel: IConnectionOpenModel) {
        const currIntl = this.props.intl;

        return currIntl.formatMessage(notificationsMessages.appNotificationConnectionOpenMessage, {
            host: connectionOpenModel.hostAndPort,
        });
    }
}

const WithIntl = injectIntl(App);

export { WithIntl as App };
