import type { NodeViewRendererProps } from '@tiptap/react';
import { TextSelection } from '@tiptap/pm/state';
import { NodeViewWrapper } from '@tiptap/react';
import React, { useCallback, useEffect, useState } from 'react';
import { TTableOfContentData } from '../../extensions/table-of-contents.types';
import theme from './TableOfContents.styles.scss';

export const TocItem = ({ item, onItemClick }) => {
    const itemPadding = `${16 * (item.level - 1)}px`;

    return (
        <span style={{ paddingLeft: itemPadding }}>
            <span>{item.itemIndex}</span>
            <a onClick={(e) => onItemClick(e, item.id)} data-item-index={item.itemIndex}>
                {item.textContent}
            </a>
        </span>
    );
};

export const TocEmptyState = ({ title }) => {
    return <p>{title}</p>;
};

export const TableOfContentsRenderer = (props: NodeViewRendererProps) => {
    const { editor, extension } = props;
    const { storage } = extension;
    const [items, setItems] = useState<TTableOfContentData>([]);

    const handleUpdate = useCallback(() => {
        setItems(storage.content);
    }, [editor]);

    useEffect(handleUpdate, []);

    useEffect(() => {
        if (!editor) {
            return;
        }

        editor.on('update', handleUpdate);

        return () => {
            editor.off('update', handleUpdate);
        };
    }, [editor]);

    const handleItemClick = (e: MouseEvent, id: string) => {
        e.preventDefault();

        if (editor) {
            const element = editor.view.dom.querySelector(`[data-toc-id="${id}"`);
            const pos = element ? editor.view.posAtDOM(element, 0) : null;

            if (!element || !pos) {
                return;
            }

            const tr = editor.view.state.tr;
            tr.setSelection(new TextSelection(tr.doc.resolve(pos)));
            editor.view.dispatch(tr);
            editor.view.focus();

            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    if (items.length === 0) {
        return (
            <NodeViewWrapper className={theme.tableOfContents}>
                <TocEmptyState title={extension.options.emptyContentTitle} />
            </NodeViewWrapper>
        );
    }

    return (
        <NodeViewWrapper className={theme.tableOfContents}>
            {items.map((item) => (
                <TocItem onItemClick={handleItemClick} key={item.id} item={item} />
            ))}
        </NodeViewWrapper>
    );
};
